import { batch } from 'react-redux';
import { get } from 'lodash';

import {
  FETCH_BASIC_DATA,
  FETCH_GA_DATA,
  FETCH_BRIZI_VISION_DATA,
  FETCH_AVERAGE_DATA,
} from './types';

import {
  getBriziBasicData,
  getGAData,
  getBriziVisionData,
  getAverageData,
} from './api';
import { displayErrorModal } from '../../helpers/swal';

const requestBasicData = () => ({ type: FETCH_BASIC_DATA });
const requestGAData = () => ({ type: FETCH_GA_DATA });
const requestBriziVision = () => ({ type: FETCH_BRIZI_VISION_DATA });
const requestAverageData = () => ({ type: FETCH_AVERAGE_DATA });

export const receiveBasicData = (error, data) => ({
  type: FETCH_BASIC_DATA,
  payload: { error, data },
});

const receiveGAData = (error, data) => ({
  type: FETCH_GA_DATA,
  payload: { error, data },
});

const receiveBriziVisionData = (error, data) => ({
  type: FETCH_BRIZI_VISION_DATA,
  payload: { error, data },
});

const receiveAverageData = (error, data) => ({
  type: FETCH_AVERAGE_DATA,
  payload: { error, data },
});

const handleAnalyticsError = (error, getState) => {
  const { events: { selectedEvents } } = getState();
  if (error.statusCode !== 404 || get(selectedEvents, '0.eventStatus') === 'post') {
    console.error(error);
    displayErrorModal();
  }
};

const fetchBriziVisionData = (slugDate) => async (dispatch, getState) => {
  dispatch(requestBriziVision());

  try {
    const data = await getBriziVisionData(slugDate);
    dispatch(receiveBriziVisionData(undefined, data));
  } catch (err) {
    handleAnalyticsError(err, getState);
    dispatch(receiveBriziVisionData(err.message));
  }
};

const fetchGAData = (slugDate) => async (dispatch, getState) => {
  dispatch(requestGAData());

  try {
    const data = await getGAData(slugDate);
    dispatch(receiveGAData(undefined, data));
    dispatch(fetchBriziVisionData(slugDate));
  } catch (err) {
    handleAnalyticsError(err, getState);
    dispatch(receiveGAData(err.message));
    dispatch(fetchBriziVisionData(slugDate));
  }
};

const fetchBasicData = (slugDate) => async (dispatch, getState) => {
  dispatch(requestBasicData());

  try {
    const data = await getBriziBasicData(slugDate);
    dispatch(receiveBasicData(undefined, data));
    dispatch(fetchGAData(slugDate));
  } catch (err) {
    handleAnalyticsError(err, getState);
    dispatch(fetchGAData(slugDate));
    dispatch(receiveBasicData(err.message));
  }
};

const fetchAverageData = (slugDate) => async (dispatch) => {
  dispatch(requestAverageData());

  try {
    const data = await getAverageData(slugDate);
    dispatch(receiveAverageData(undefined, data));
  } catch (err) {
    console.error(err);
    displayErrorModal();
    dispatch(receiveAverageData(err.message));
  }
};
// eslint-disable-next-line import/prefer-default-export
export const fetchAnalyticsData = (slugDate) => (dispatch) => {
  batch(() => {
    dispatch(fetchBasicData(slugDate));
    dispatch(fetchAverageData(slugDate));
  });
};
