import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { Spinner, Button } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import classNames from 'classnames';
import hash from 'object-hash';
import { saveAs } from 'file-saver';

import EventSelector from '../../../components/EventSelector/Modal';
import EventSelectorButton from '../../../components/EventSelector/Button';
import ColorsForm from './ColorsForm';
import UpdateButton from '../UpdateButton';
import { setColors } from './actions';

import { generateScreenshots, generatePreviewScreenshots, updateColors } from './apis';
import { useUpdateEvent } from '../hooks';
import { displaySuccessModal, displayErrorModal } from '../../../helpers/swal';
import imgPlaceholder from '../../../assets/images/image-placeholder.svg';

import style from './style.module.scss';

const { origin } = window.location;
let screenshots = [
  { page: 'components', path: 'showcase/main', group: 'Main Colors' },
  { page: 'icons', path: 'showcase/icons', group: 'Main Colors' },
  { page: 'srsIcons', path: 'showcase/srsIcons', group: 'Main Colors' },
  { page: 'home', path: '', group: 'Onboarding' },
  { page: 'home-language', path: 'preview/language', group: 'Onboarding' },
  { page: 'onboarding', path: 'onboarding', group: 'Onboarding' },
  { page: 'terms', path: 'terms', group: 'Onboarding' },
  { page: 'findme-section', path: 'findme/srs/section', group: 'SRS' },
  { page: 'findme-confirm', path: 'findme/confirm', group: 'SRS' },
  { page: 'camera', path: 'camera', group: 'Photo-Taking' },
  { page: 'taking-photo', path: 'taking-photo', group: 'Photo-Taking' },
  { page: 'album', path: 'album', group: 'Album' },
  { page: 'snapshot', path: 'snapshot/1', group: 'Album' },
  { page: 'share', path: 'share/1', group: 'Share' },
  {
    page: 'snapshot-share',
    path: 'api/email/sendemailsnap/sample',
    server: true,
    group: 'Email',
  },
];

const Colors = ({ colors, event }) => {
  const dispatch = useDispatch();
  const isEventUpdating = useUpdateEvent();
  const [selectedPage, setSelectedPage] = useState(screenshots[0]);
  const [isLoading, setIsLoading] = useState(false);
  const [hashStr, setHashStr] = useState();
  const previewRef = useRef(false);

  useEffect(() => {
    if (event) {
      setHashStr(event.screenshotsHash);

      if (event.availableLanguages.length <= 1) {
        screenshots = screenshots.filter(({ page }) => (page !== 'home-language'));
      }
    }
  }, [event]);

  const getImage = ({ page }, alt) => {
    if (!hashStr) {
      return (
        <div className={style.imgPlaceholder}>
          <img src={imgPlaceholder} alt={alt} />
        </div>
      );
    }

    const url = `${origin}/screenshots/${previewRef.current ? 'preview' : 'saved'}/${hashStr}/${page}.jpeg`;
    return <img src={url} alt={alt} />;
  };

  const handleExportColors = () => {
    if (!event) return;

    const data = { colors };

    const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
    saveAs(blob, `${event.slugDate}-colors.json`);
  };

  const isValidHex = (color) => /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(color);

  const handleImportColors = (evt) => {
    const fileInput = evt.target;
    const file = fileInput.files[0];
    if (!file) return;

    if (file.type !== 'application/json') {
      displayErrorModal({ text: 'Invalid file type. Please upload a JSON file.' });
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const jsonData = JSON.parse(e.target.result);
        if (!jsonData.colors) {
          displayErrorModal({ text: 'No "colors" property found in the file.' });
          return;
        }

        const {
          theme = {},
          iconColor = {},
          emailSettings = {},
          slideshowColor,
        } = jsonData.colors;

        const validTheme = Object.fromEntries(
          Object.entries(theme).filter(([, color]) => isValidHex(color)),
        );
        const validIconColor = Object.fromEntries(
          Object.entries(iconColor).filter(([, color]) => isValidHex(color)),
        );
        const validEmailSettings = Object.fromEntries(
          Object.entries(emailSettings).filter(([, color]) => isValidHex(color)),
        );
        const validSlideshowColor = isValidHex(slideshowColor) ? slideshowColor : undefined;

        const hasValidColors = Object.keys(validTheme).length > 0
        || Object.keys(validIconColor).length > 0
        || Object.keys(validEmailSettings).length > 0
        || validSlideshowColor;

        if (!hasValidColors) {
          displayErrorModal({ text: 'No valid color settings found in the file.' });
          return;
        }

        dispatch(
          setColors({
            theme: { ...colors.theme, ...validTheme },
            iconColor: { ...colors.iconColor, ...validIconColor },
            emailSettings: { ...colors.emailSettings, ...validEmailSettings },
            slideshowColor: validSlideshowColor !== undefined
              ? validSlideshowColor
              : colors.slideshowColor,
          }),
        );
        displaySuccessModal({ text: 'Imported Colors Successfully.' });
      } catch (error) {
        displayErrorModal({
          text: error instanceof SyntaxError
            ? 'Invalid JSON format. Please check the file.'
            : 'Failed to Import Colors.',
        });
        console.error('Import error:', error);
      }
    };

    reader.onloadend = () => {
      fileInput.value = null;
    };

    reader.readAsText(file);
  };

  const requestScreenshots = useCallback(async ({ preview = true, eventIds }) => {
    setIsLoading(true);
    try {
      const newHash = hash({ ...colors, slugDate: event.slugDate });
      if (preview) {
        const { slugDate } = event;
        const { theme, iconColor, emailSettings } = colors;
        await generatePreviewScreenshots({
          screenshots,
          hash: newHash,
          slugDate,
          theme,
          iconColor,
          emailSettings,
        });
        previewRef.current = true;
      } else {
        previewRef.current = false;
        await generateScreenshots({ hash: newHash, screenshots, eventIds });
      }
      setHashStr(newHash);
    } catch (err) {
      // TODO: we need to either have a UI message or repeat the request
      console.error('Error generating screenshots', err);
    } finally {
      setIsLoading(false);
    }
  }, [event, colors]);

  const updateEventHandler = useCallback(async (eventIds) => {
    await updateColors({ eventIds, colors });
    await requestScreenshots({ preview: false, eventIds });
  }, [event, colors]);

  return (
    <div className="d-flex flex-column flex-grow-1">
      <div className="pageHeader">
        <h4>UI Images</h4>
        <EventSelectorButton showFutureEvents />
        <div className="d-flex flex-row">
          <Button variant="outline-primary" size="sm" className={classNames('mr-2 rounded-pill', style.importBtn)}>
            Import
            <input type="file" onChange={handleImportColors} />
          </Button>
          <Button variant="outline-primary" size="sm" onClick={handleExportColors} className="mr-2 rounded-pill">
            Export
          </Button>
          <UpdateButton label="Update Event" updateHandler={updateEventHandler} />
        </div>
      </div>

      {isEventUpdating && (
        <div className="flex-grow-1 d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {!isEventUpdating && selectedPage && (
        <div className={style.gridContainer}>
          <div className={classNames('d-flex', 'flex-column', style.formArea)}>
            <div className="d-flex">
              <div className="flex-grow-1">
                <p className="mb-0">
                  Add your brand colours the Amplifi mobile web app.
                  Colors here will affect the entire experience.
                </p>
              </div>
              <div>
                <Button
                  className="small text-nowrap"
                  variant="outline-primary"
                  onClick={() => requestScreenshots({ preview: true })}
                >
                  Preview all
                </Button>
              </div>
            </div>
            <ColorsForm />
          </div>
          <div className={style.previewArea}>
            <div>
              <h5>{selectedPage.page}</h5>
              <div className={style.previewContainer}>
                <div>
                  {isLoading
                    ? (
                      <div className="d-flex align-items-center">
                        <Spinner animation="border" variant="primary" />
                      </div>
                    )
                    : getImage(selectedPage, 'main preview')}
                </div>
              </div>
            </div>
          </div>
          <div className={style.carouselArea}>
            <div className={style.PagesCarousel}>
              {screenshots.map((item, index) => (
                // eslint-disable-next-line
                <div key={item.page} className={style.carouselItem} onClick={() => setSelectedPage(item)}>
                  <h6>
                    {`${index === 0 || screenshots[index - 1].group !== item.group ? item.group : ' '}`}
                  </h6>
                  <div
                    className={classNames(
                      style.imageContainer,
                      { [style.active]: item === selectedPage },
                    )}
                  >
                    {isLoading
                      ? <Spinner animation="border" variant="primary" />
                      : getImage(item, item.page)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      <EventSelector singleSelection showTemplate showFutureEvents />
    </div>
  );
};

export default connect(
  ({
    colors,
    events: { selectedEvents },
  }) => ({
    colors,
    event: selectedEvents[0],
  }),
)(Colors);
