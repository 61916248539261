import React, { useState } from 'react';
import {
  Button,
  Modal,
  Form,
  Col,
  Spinner,
} from 'react-bootstrap';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';

import Alert from '../../../components/Alert';
import Info from '../../../components/Info';
import { createEvent, updateEvent } from '../../../helpers/api';
import { dateTimeFormat } from '../../../helpers/constants';
import { displayErrorModal } from '../../../helpers/swal';
import {
  onInputChange,
  fetchEvents,
  clearEventForm,
  fetchCurrentEvent,
} from '../../../store/actions/events';

import style from './style.module.scss';

const EventFormModal = (props) => {
  const {
    showEventModal = false,
    timezone,
    slug,
    eventForm,
    onInputChangeAction,
    fetchEventsAction,
    fetchCurrentEventAction,
    clearEventFormAction,
  } = props;

  const [timeValidationError, setTimeValidationError] = useState(false);
  const [timeValidationMessage, setTimeValidationMessage] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);

  const onValueChangeHandler = ({ name, value }) => {
    // This block is to prevent the the event's day to revert to today's date
    // when changing startTime or endTime manually (not from dropdown)
    if (
      eventForm.formMode === 'update'
    && (name === 'startTime' || name === 'endTime')
    && value instanceof Date
    && eventForm[name] instanceof Date
    ) {
      const originalDate = moment(eventForm[name]);
      const newTime = moment(value)
        .year(originalDate.year())
        .month(originalDate.month())
        .date(originalDate.date());
      // eslint-disable-next-line no-param-reassign
      value = newTime.toDate();
    }

    onInputChangeAction({ [name]: value });

    // if an event end in the next day and we unchecked the next day
    // end time will still have same value because the input takes a date object
    // so we need to set end day to be same as start day when unchecked
    if (name === 'isNextDay' && eventForm.endTime && !value) {
      const startDay = eventForm.startTime.getDate();
      onInputChangeAction({ endTime: new Date(eventForm.endTime.setDate(startDay)) });
    }
  };

  const clearState = () => {
    setTimeValidationError(false);
    setTimeValidationMessage('');
    setFormSubmitted(false);
  };

  const closeModalHandler = () => {
    clearState();
    clearEventFormAction();
  };

  const onSubmitHandler = async () => {
    try {
      const promises = eventForm.selectedSlots.map(async (localDate) => {
        const date = moment(localDate).format(dateTimeFormat);
        const gameDate = moment.tz(date, timezone).startOf('day').format();
        const startTime = moment.tz(date, timezone)
          .hour(eventForm.startTime.getHours())
          .minute(eventForm.startTime.getMinutes())
          .format();
        const endTime = moment.tz(date, timezone)
          .add(eventForm.isNextDay ? 1 : 0, 'day')
          .hour(eventForm.endTime.getHours())
          .minute(eventForm.endTime.getMinutes())
          .format();

        const event = {
          gameDate,
          slugDate: `${slug}-${moment.tz(date, timezone).format('YYYY-MM-DD')}`,
          startTime,
          endTime,
          title: eventForm.eventTitle,
        };
        const createdEvent = await createEvent(event);
        return createdEvent;
      });

      await Promise.all(promises);
      await fetchEventsAction();
      fetchCurrentEventAction();
      clearEventFormAction();
      clearState();
    } catch (err) {
      console.error('Error trying to create the event', err);
      displayErrorModal({ text: 'Something went wrong, Please try again' });
    }
  };

  // The UI forces local timezone, so date objects are converted to it. However, we need
  // to display the exact day/time as in the event's timezone, not the local one.
  // This function ensures the `fromDate` used in the update endpoint matches the event's
  // timezone, preventing mismatches (e.g., showing the wrong day when user and event
  // timezones differ).
  const isolateLocalTimezone = (oldDate) => {
    if (!oldDate) return null;

    const date = moment.tz(oldDate, timezone).format('YYYY-MM-DD');
    const [year, month, day] = date.split('-').map(Number);

    return new Date(year, month - 1, day);
  };

  // This function combines the isolated fromDate in event timezone with the event's startTime and
  // endTime to pass to the update endpoint
  const combineDateAndTime = (timeObj, dateObj, eventTimezone) => {
    if (!timeObj || !dateObj) return null;

    const isolatedDay = isolateLocalTimezone(dateObj);

    if (!isolatedDay) return null;

    const year = isolatedDay.getFullYear();
    const month = String(isolatedDay.getMonth() + 1).padStart(2, '0'); // JavaScript months are 0-indexed
    const day = String(isolatedDay.getDate()).padStart(2, '0');

    const hours = String(timeObj.getHours()).padStart(2, '0');
    const minutes = String(timeObj.getMinutes()).padStart(2, '0');
    const seconds = String(timeObj.getSeconds()).padStart(2, '0');

    const combined = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return moment.tz(combined, 'YYYY-MM-DD HH:mm:ss', eventTimezone);
  };

  const updateEventHandler = async () => {
    const startTime = combineDateAndTime(
      eventForm.startTime,
      eventForm.fromDate,
      timezone,
    ).format();

    const endTime = combineDateAndTime(
      eventForm.endTime,
      eventForm.isNextDay ? moment(eventForm.fromDate).add(1, 'day').toDate() : eventForm.fromDate,
      timezone,
    ).format();

    const updateObject = {
      data: {
        title: eventForm.eventTitle,
        startTime,
        endTime,
      },
      slugDate: eventForm.slugDate,
    };

    try {
      await updateEvent(updateObject);
      await fetchEventsAction();
      closeModalHandler();
      await Alert.fire({ title: 'Event has been updated' });
    } catch (err) {
      console.error('Error trying to update the event', err);
      await Alert.fire({ title: 'Error trying to update the event' });
    }
  };

  const validateForm = () => {
    if (!eventForm.eventTitle?.length) {
      return false;
    }

    if (!eventForm.startTime) {
      return false;
    }

    if (!eventForm.endTime) {
      return false;
    }

    const startTimeM = moment(eventForm.startTime);
    const endTimeM = moment(eventForm.endTime).date(startTimeM.date());

    if (startTimeM.diff(endTimeM) >= 0 && !eventForm.isNextDay) {
      setTimeValidationError(true);
      setTimeValidationMessage('End time should be after start time');
      return false;
    }

    if (endTimeM.diff(startTimeM, 'minutes') >= 0 && eventForm.isNextDay) {
      setTimeValidationError(true);
      setTimeValidationMessage('Event should not last more than 24 hours');
      return false;
    }

    if (endTimeM.diff(startTimeM, 'minutes') < 5 && !eventForm.isNextDay) {
      setTimeValidationError(true);
      setTimeValidationMessage('Event should last for at least 5 minutes');
      return false;
    }
    setTimeValidationError(false);
    setTimeValidationMessage('');
    return true;
  };

  // eslint-disable-next-line consistent-return
  const onSubmit = () => {
    setFormSubmitted(true);
    const isFormValid = validateForm();
    if (isFormValid) {
      if (eventForm.formMode === 'create') {
        return onSubmitHandler();
      }
      updateEventHandler();
    }
  };

  return (
    <Modal show={showEventModal} className={style.modalContainer} size="sm" backdrop onHide={closeModalHandler} centered>
      <Modal.Header closeButton />
      <Modal.Body className={style.calendarFormModal}>
        <Form className={style.eventForm} noValidate>
          <Form.Group controlId="eventTitle">
            <Form.Label>Event Name</Form.Label>
            <Form.Control
              type="text"
              name="eventTitle"
              placeholder="Add event title..."
              value={eventForm.eventTitle || ''}
              onChange={(e) => onValueChangeHandler(e.target)}
              autoComplete="nope"
              isInvalid={
                formSubmitted && (
                  !eventForm.eventTitle?.length
                )
              }
              required
            />
            <Form.Control.Feedback type="invalid">
              Event name is required
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Row>
            <Col>
              <Form.Label disabled>Event Date</Form.Label>
              <DatePicker
                className="form-control"
                selected={isolateLocalTimezone(eventForm.fromDate)}
                dateFormat="MMM d, yyyy"
                disabled
              />

            </Col>
            {eventForm.selectedSlots.length > 1 && (
              <Col>
                <Form.Label className="disabled-label">Occurs Daily Until</Form.Label>
                <DatePicker
                  className="form-control"
                  dateFormat="MMM d, yyyy"
                  selected={eventForm.toDate}
                  disabled
                />
              </Col>
            )}
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Group className="mb-0" controlId="eventStartTime">
                <Form.Label>Starts at</Form.Label>
                <DatePicker
                  className={`form-control ${(formSubmitted && !eventForm.startTime) && 'is-invalid'}`}
                  selected={eventForm.startTime}
                  value={eventForm.startTime}
                  onChange={(value) => onValueChangeHandler({ name: 'startTime', value })}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  dateFormat="h:mm aa"
                  timeCaption="Time"
                  autoComplete="nope"
                  placeholderText="Event start"
                />
                <div className="invalid-feedback" style={{ display: 'block' }}>
                  {(formSubmitted && !eventForm.startTime) && 'Start time is required'}
                </div>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-0" controlId="eventEndTime">
                <Form.Label>Ends at</Form.Label>
                <DatePicker
                  className={`form-control ${((formSubmitted && !eventForm.endTime)) && 'is-invalid'}`}
                  selected={eventForm.endTime}
                  onChange={(value) => onValueChangeHandler({ name: 'endTime', value })}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  dateFormat="h:mm aa"
                  timeCaption="Time"
                  autoComplete="nope"
                  placeholderText="Event end"

                />
                <div className="invalid-feedback" style={{ display: 'block' }}>
                  {(formSubmitted && !eventForm.endTime) && 'End time is required'}
                </div>
              </Form.Group>
            </Col>
            <Col className={style.nextDayContainer}>
              <Form.Group className="mb-0" controlId="formBasicCheckbox">
                <Form.Check
                  custom
                  type="checkbox"
                  label={<Info label="Next Day" content="Check If the event ends in the next day" className="ml-2" />}
                  value={eventForm.isNextDay}
                  defaultChecked={eventForm.isNextDay}
                  name="isNextDay"
                  onChange={(e) => onValueChangeHandler({ name: 'isNextDay', value: e.target.checked })}
                />
              </Form.Group>
            </Col>
          </Form.Row>
          {timeValidationError && (
            <span>
              {timeValidationMessage}
            </span>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" className="small" onClick={closeModalHandler}>Cancel</Button>
        {!eventForm.isFormSubmitting && (
          <Button className="small" onClick={onSubmit}>Submit Event</Button>
        )}
        {eventForm.isFormSubmitting && (
          <Button className="small" variant="primary" disabled>
            <Spinner as="span" animation="border" role="status" aria-hidden="true" />
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default connect(
  ({
    events: {
      eventForm,
      showEventModal,
      timezone,
      slug,
    },
  }) => ({
    eventForm,
    showEventModal,
    timezone,
    slug,
  }),
  {
    onInputChangeAction: onInputChange,
    fetchEventsAction: fetchEvents,
    fetchCurrentEventAction: fetchCurrentEvent,
    clearEventFormAction: clearEventForm,
  },
)(EventFormModal);
