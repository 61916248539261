import { saveAs } from 'file-saver';
import { displaySuccessModal, displayErrorModal } from '../../../../helpers/swal';

export const exportHandler = (camera) => {
  const exportedData = {
    cameraKey: camera.cameraKey,
    configs: camera.configs.map(({ configKey, configValue }) => ({
      configKey,
      configValue,
    })),
  };

  const blob = new Blob([JSON.stringify(exportedData, null, 2)], {
    type: 'application/json',
  });
  saveAs(blob, `${camera.cameraKey}-config.json`);
};

export const importHandler = (event, camera, setValue) => {
  const fileInput = event.target;
  const file = fileInput.files[0];
  if (!file) return;

  if (file.type !== 'application/json') {
    displayErrorModal({ text: 'Invalid file type. Please upload a JSON file.' });
    return;
  }

  const reader = new FileReader();
  reader.onload = (e) => {
    try {
      const importedConfigs = JSON.parse(e.target.result);

      importedConfigs.configs.forEach(({ configKey, configValue }) => {
        setValue(`cameraConfigs.${camera._id}.${configKey}`, configValue);
      });

      displaySuccessModal({ text: `Configs imported successfully to ${camera.cameraKey}!`, timer: 5000 });
    } catch (err) {
      console.error('Invalid JSON file', err);
      displayErrorModal({ text: 'Invalid JSON format', timer: 5000 });
    }
  };

  reader.onloadend = () => {
    fileInput.value = null;
  };

  reader.readAsText(file);
};

