import React, { useState, useContext } from 'react';
import useForm from 'react-hook-form';
import { Table } from 'react-bootstrap';
import { SocketContext } from 'react-socket-io';
import { connect } from 'react-redux';
import { get } from 'lodash';

import CameraHeader from './CameraHeader';
import CameraConfigRow from './CameraConfigRow';
import CameraControls from './CameraControls';
import HostInfoRow from './HostInfoRow';

import Loader from '../../../../shared/Loader';
import { displaySuccessModal, displayErrorModal } from '../../../../helpers/swal';

import { updateCameraConfigs } from '../api';
import { fetchCamera } from '../actions';

import style from './style.module.scss';

const CameraSettingsTable = ({ cameras, isFetching, fetchCameraAction }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const socket = useContext(SocketContext);
  const { handleSubmit, register, setValue } = useForm();

  const restartACSHandler = (cameraKey) => {
    try {
      socket.emit('restart acs', cameraKey);
      displaySuccessModal({
        text: `Restarted ACS succesfully on ${cameraKey}`,
        timer: 5000,
      });
    } catch (err) {
      displayErrorModal({
        text: `Error trying to restart ACS on ${cameraKey}, try again`,
        timer: 5000,
      });
      console.error('Error trying to restart ACS', err);
    }
  };

  if (!cameras.length) {
    if (isFetching) {
      return <p>Loading available Cameras</p>;
    }
    return <p>No cameras available</p>;
  }

  const configKeys = Array.from(
    new Set(cameras.flatMap((camera) => camera.configs.map((item) => item.configKey))),
  );

  const getConfigByKey = (camera, key) => camera.configs.find((config) => config.configKey === key);

  const categorizeConfig = (configKey) => {
    const [category, subCategory, key, subKey] = configKey.split('.');
    let currentCategory = category;
    let currentLabel = subCategory;

    if (!subCategory) {
      currentCategory = 'General Settings';
      currentLabel = category;
    }

    if (category === 'acs') {
      currentCategory = !key ? 'General Settings' : subCategory;
      if (!key) {
        currentLabel = subCategory;
      } else {
        currentLabel = !subKey ? key : `${key}: ${subKey}`;
      }
    }

    return { currentCategory, currentLabel };
  };

  const onSubmit = async (data, camera = null) => {
    setIsSubmitting(true);

    let focusedCamera = camera;

    if (!camera) {
      const { activeElement } = document;
      const activeInputName = activeElement.name;
      const cameraKeyMatch = activeInputName.match(/cameraConfigs\.(.*?)\./);
      const focusedCameraKey = cameraKeyMatch ? cameraKeyMatch[1] : null;

      if (!focusedCameraKey) {
        console.error('Could not determine the focused camera.');
        setIsSubmitting(false);
        return;
      }

      focusedCamera = cameras.find((cam) => cam._id === focusedCameraKey);
    }

    if (!focusedCamera) {
      console.error('Focused camera not found.');
      setIsSubmitting(false);
      return;
    }

    const previousMode = getConfigByKey(focusedCamera, 'acs.rover.mode')?.configValue;
    const newMode = get(data, `cameraConfigs.${focusedCamera._id}.acs.rover.mode`);

    const cameraObj = {
      camera: focusedCamera.cameraKey,
      configs: data.cameraConfigs ? data.cameraConfigs[focusedCamera._id] : [],
      _id: focusedCamera._id,
    };

    try {
      await updateCameraConfigs(focusedCamera.cameraKey, cameraObj);
      fetchCameraAction(focusedCamera.cameraKey);
      setIsSubmitting(false);
      displaySuccessModal({
        text: `${focusedCamera.cameraKey} updated successfully`,
        timer: 5000,
      });

      // if mode changes from capture to rover or vice versa, restart ACS
      if (previousMode !== newMode) {
        console.log(`acs.rover.mode changed from ${previousMode} to ${newMode}, restarting ACS.`);
        socket.emit('restart acs', focusedCamera.cameraKey);
      }
    } catch (err) {
      setIsSubmitting(false);
      displayErrorModal({
        text: `Error trying to update ${focusedCamera.cameraKey}, try again`,
        timer: 5000,
      });
      console.error('Error trying to update config', err);
    }
  };

  const categories = {};

  configKeys.forEach((key) => {
    const { currentCategory, currentLabel } = categorizeConfig(key);
    if (!categories[currentCategory]) {
      categories[currentCategory] = [];
    }
    categories[currentCategory].push({ key, label: currentLabel });
  });

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit((data) => onSubmit(data))();
    }
  };

  return (
    <>
      {isSubmitting && <Loader />}
      <div role="button" tabIndex={0} onKeyDown={handleKeyPress} onClick={() => {}}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Table striped hover responsive className={style.CameraSettingsTable}>
            <thead>
              <CameraHeader cameras={cameras} />
            </thead>
            <tbody>
              <HostInfoRow cameras={cameras} />
              {Object.keys(categories).map((category) => (
                <React.Fragment key={category}>
                  <tr>
                    <td colSpan={cameras.length + 1} className={style.ThemeYellowBackground}>
                      {category}
                    </td>
                  </tr>
                  {categories[category].map(({ key, label }) => {
                    const config = getConfigByKey(cameras[0], key);
                    return (
                      <CameraConfigRow
                        key={key}
                        cameras={cameras}
                        config={config}
                        label={label}
                        configKey={key}
                        register={register}
                        setValue={setValue}
                        getConfigByKey={getConfigByKey}
                      />
                    );
                  })}
                </React.Fragment>
              ))}
              <CameraControls
                cameras={cameras}
                restartACSHandler={restartACSHandler}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                setValue={setValue}
              />
            </tbody>
          </Table>
        </form>
      </div>
    </>
  );
};

export default connect(
  ({ cameraConfigs: { isFetching } }) => ({ isFetching }),
  { fetchCameraAction: fetchCamera },
)(CameraSettingsTable);
