import React from 'react';
import { Button } from 'react-bootstrap';
import style from '../style.module.scss';

import { importHandler, exportHandler } from '../cameraUtils';

const CameraControls = ({
  cameras,
  restartACSHandler,
  handleSubmit,
  onSubmit,
  setValue,
}) => (
  <tr>
    <td />
    {cameras.map((camera) => (
      <td key={camera._id}>
        <div className={style.ButtonWrapper}>
          <div className="mb-2">
            <Button
              className={style.Button}
              variant="outline-primary"
              size="sm"
              onClick={() => restartACSHandler(camera.cameraKey)}
            >
              Restart ACS
            </Button>
            <Button
              className={style.Button}
              size="sm"
              variant="primary"
              onClick={handleSubmit((data) => onSubmit(data, camera))}
            >
              Submit
            </Button>
          </div>
          <div className="mb-2">
            <label htmlFor={`import-${camera._id}`}>
              <Button
                as="span"
                size="sm"
                variant="outline-primary"
                className={style.importButton}
              >
                Import
              </Button>
              <input
                id={`import-${camera._id}`}
                type="file"
                style={{ display: 'none' }}
                onChange={(e) => importHandler(e, camera, setValue)}
              />
            </label>
            <Button
              className={style.Button}
              size="sm"
              variant="outline-primary"
              onClick={(() => exportHandler(camera))}
            >
              Export
            </Button>
          </div>
        </div>
      </td>
    ))}
  </tr>
);

export default CameraControls;
