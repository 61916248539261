import React from 'react';
import { connect } from 'react-redux';
import {
  isNil,
  isEmpty,
  round,
  floor,
} from 'lodash';
import { Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import {
  faImage,
  faMobile,
  faShare,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';
import { faUsersViewfinder } from '@fortawesome/pro-solid-svg-icons';

import Loader from '../../../shared/Loader';
import Info from '../../../components/Info';
import GroupMemoryChart from './GroupMemoryChart';
import ShareChart from './ShareChart';
import SectionChart from './HistogramChart';
import OverlayChart from './OverlayChart';
import OverviewContainer from './OverviewContainer';

import style from './style.module.scss';

const numberFormat = Intl.NumberFormat();

const BasicSection = ({
  basicData,
  averageData,
  error,
  isFetching,
}) => {
  if (isFetching) {
    return <Loader />;
  }

  if (error) {
    return (
      <div>
        Error querying Basic Data for the selected event
      </div>
    );
  }

  if (!isNil(basicData) && !isEmpty(basicData) && !isNil(averageData)) {
    const { overlayUsage, shareInsights } = basicData;
    const isOverlayFullRow = overlayUsage.length > 3;
    const isOverlayChartEmpty = isEmpty(basicData.overlayUsage);
    const isShareChartEmpty = isEmpty(shareInsights);

    return (
      <div>
        <h4>Overview</h4>
        <Row>
          <Col xs="12" sm="6" md="6" lg="3" className="mb-2">
            <OverviewContainer
              title="Engaged Users"
              description="This represents the total engaged users, calculated by multiplying the number of devices by the average group size. Our AI analyzes images and clips to detect faces and estimate group sizes accurately. Faces obscured by overlays are excluded from this calculation."
              value={floor(round(basicData?.engagedUsers?.average, 2) * (basicData.users || 0))}
              icon={faUsersViewfinder}
              variant="green"
              average={averageData.users}
              cardPlacement="right"
            >
              <Card className={classnames('p-3', 'ms-auto', style.engagedUsersCard)}>
                <h5 className="mb-1">
                  <span>Median:</span>
                  {' '}
                  <b>{basicData?.engagedUsers?.average ? basicData?.engagedUsers?.median : 'NA'}</b>

                </h5>
                <h5 className="mb-0">
                  <span>Average:</span>
                  {' '}
                  <b>{basicData?.engagedUsers?.average ? round(basicData?.engagedUsers?.average, 2) : 'NA'}</b>
                </h5>
              </Card>
            </OverviewContainer>
          </Col>
          <Col xs="12" sm="6" md="6" lg="3" className="mb-2">
            <OverviewContainer
              title="Devices"
              description="This is the number of devices that have used the app to take their snapshot. E.g. If 10 unique users take 20 photos total, this would count as 10 devices"
              value={basicData.users}
              icon={faMobile}
              variant="yellow"
              average={averageData.users}
            />
          </Col>
          <Col xs="12" sm="6" md="6" lg="3" className="mb-2">
            <OverviewContainer
              title="Photos"
              description="This is the number of total photos taken. E.g. If 10 devices take 20 photos total, this would count as 20 photos"
              value={basicData.snapshots}
              icon={faImage}
              variant="purple"
              average={averageData.snapshots}
            />
          </Col>
          <Col xs="12" sm="6" md="6" lg="3">
            <OverviewContainer
              title="Shares"
              description={(
                <>
                  This is the number of share actions taken by photographers. E.g. If 10
                  photographers take 20 photos and 5 were shared, this would count as 5 shares.
                  <br />
                  Click on the arrow to see a detailed breakdown
                </>
              )}
              value={basicData.shares}
              icon={faShare}
              variant="red"
              average={averageData.shares}
            >
              <Card className={classnames('p-3', style.shareRateCard)}>
                <h5 className="mb-1">Share Details</h5>
                <h6 className={classnames('mb-0', style.cardDesc)}>
                  Photos being shared by your audiences to their personal networks.
                </h6>
                <div className="d-flex mt-2 align-items-center">
                  <div className={style.cardIcon}>
                    <FontAwesomeIcon icon={faUser} />
                  </div>
                  <div className="ml-2">
                    <h3 className="mb-1">
                      {numberFormat.format(basicData.shareRate)}
                      %
                      <Info content="The percentage of users who shared. E.g. If 10 users took photos, and 5 users shared one or more photos, then the user share rate would be 50%" className="ml-2 align-baseline" />
                    </h3>
                    <h6 className={classnames('mb-0', style.rowDesc)}>User Share Rate</h6>
                  </div>
                </div>
                <div className="d-flex mt-3 align-items-center">
                  <div className={style.cardIcon}>
                    <FontAwesomeIcon icon={faImage} />
                  </div>
                  <div className="ml-2">
                    <h3 className="mb-1">
                      {numberFormat.format(basicData.photoShareRate)}
                      %
                      <Info content="The percentage of photos shared. E.g. If 10 users took 20 photos, and 5 photos were shared in total, then the photo share rate would be 25%." className="ml-2 align-baseline" />
                    </h3>
                    <h6 className={classnames('mb-0', style.rowDesc)}>Photo Share Rate</h6>
                  </div>
                </div>
              </Card>
            </OverviewContainer>
          </Col>
        </Row>
        <div className="mt-4">
          <Row className="mt-4">
            <Col>
              <GroupMemoryChart />
            </Col>
          </Row>
          <Row>
            {!isOverlayChartEmpty && (
              <Col md={isOverlayFullRow ? 12 : 8} className="mt-4">
                <OverlayChart />
              </Col>
            )}
            <Col md={4} className="mt-4">
              <ShareChart />
            </Col>
            <Col
              md={(isOverlayFullRow || isOverlayChartEmpty) && !isShareChartEmpty ? 8 : 12}
              className="mt-4"
            >
              <SectionChart />
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  return null;
};

export default connect(
  ({
    analytics: {
      basic: { isFetching, data: basicData, error },
      average: { data: averageData },
    },
  }) => ({
    isFetching,
    basicData,
    error,
    averageData,
  }),
)(BasicSection);
