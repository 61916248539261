import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faPlay } from '@fortawesome/pro-regular-svg-icons';

/**
 * MediaTypeIndicator Component
 *
 * This component displays an icon on the snapshot item
 * indicating the type of media (image or video) based on
 * the mediaType property.
 */

const MediaTypeIndicator = ({ mediaType }) => (
  <div className="mediaTypeIndicator">
    <FontAwesomeIcon icon={mediaType === 'video/mp4' ? faPlay : faImage} />
  </div>
);

export default MediaTypeIndicator;
