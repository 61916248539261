import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import ImageGallery from 'react-image-gallery';
import { range, random, isNil } from 'lodash';
import { Event, Socket } from 'react-socket-io';
import 'react-image-gallery/styles/scss/image-gallery.scss';

import banner from '../../assets/slideshow/bannerBrizi.png';
import style from './style.module.scss';

import { getSlideshowImages } from './api';
import { urls } from '../../helpers/urls';

const { websocket } = urls;

let sliderAvailableIndexes = [];
let currentInd = 0;

const defaultSettings = {
  transitionTime: 5000,
  transitionDuration: 1000,
  animation: 'ease-in-out',
  backgroundColor: '#fff',
  slideshowBannerUrl: banner,
};

const Slideshow = () => {
  const [images, setImages] = useState(null);
  const [hasImages, setHasImages] = useState(false);
  const [settings, setSettings] = useState(defaultSettings);

  const imageGallery = useRef();
  const slideshowContainer = useRef();

  const refreshAvailableArray = () => {
    sliderAvailableIndexes = range(images.length);
  };

  // custom renderItem property for react-image-gallery to handle rendering videos
  const renderItem = useCallback((item) => {
    if (item.isVideo) {
      return (
        <div className="image-gallery-image">
          <video
            autoPlay
            loop
            muted
            playsInline
            className="imageClass"
            style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
          >
            <source src={item.original} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      );
    }
    return (
      <div className="image-gallery-image">
        <img src={item.original} alt="" className="imageClass" />
      </div>
    );
  }, []);

  const fetchFavorited = useCallback(async () => {
    try {
      const { snapshots, slideshowSettings } = await getSlideshowImages();
      if (!isNil(slideshowSettings)) {
        setSettings((prevSettings) => ({
          ...prevSettings,
          ...slideshowSettings,
        }));
      }

      if (!snapshots.length) {
        setHasImages(false);
        return;
      }

      const imagesTemp = snapshots.map(({ sharedURI, smallURI }) => ({
        originalClass: 'imageClass',
        original: sharedURI,
        thumbnail: smallURI,
        isVideo: sharedURI.endsWith('.mp4'),
      }));

      setImages(imagesTemp);
      currentInd = 0;
      setHasImages(true);
    } catch (err) {
      console.error('Error trying to retrieve slideshow images', err);
      setHasImages(false);
    }
  }, []);

  useEffect(() => {
    if (images) {
      refreshAvailableArray();
    }
  }, [images]);

  useEffect(() => {
    if (slideshowContainer.current) {
      const {
        backgroundColor,
        animation,
        transitionDuration,
      } = settings;

      slideshowContainer.current.style.setProperty('--slideshow-bg-color', backgroundColor);
      slideshowContainer.current.style.setProperty('--slideshow-transition', animation);
      slideshowContainer.current.style.setProperty('--slideshow-transition-duration', transitionDuration);
    }
  }, [settings]);

  useEffect(() => {
    fetchFavorited();
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    let interval;
    if (images?.length > 1) {
      interval = setInterval(() => {
        if (imageGallery.current) {
          const availableIndexes = sliderAvailableIndexes.filter((n) => n !== currentInd);
          const nextInd = availableIndexes[random([availableIndexes.length - 1])];
          sliderAvailableIndexes = sliderAvailableIndexes.filter((n) => n !== nextInd);

          if (sliderAvailableIndexes.length === 0) {
            refreshAvailableArray();
          }

          if (nextInd !== undefined) {
            currentInd = nextInd;
            imageGallery.current.slideToIndex(nextInd);
          }
        }
      }, settings.transitionTime);
    }

    return () => clearInterval(interval);
  }, [images, settings.transitionTime]);

  return (
    <Socket uri={websocket} options={{ transports: ['websocket'] }}>
      <div className={style.slideShowPage} ref={slideshowContainer}>
        <Event event="monitor favoritedSnapshots" handler={fetchFavorited} />

        {hasImages === null && (
          <div className={style.loadingContainer}>
            LOADING
          </div>
        )}

        {hasImages === false && (<div className={style.noImageContainer}>NO IMAGES AVAILABLE</div>)}

        {hasImages && (
          <div className={style.slideShowContent}>
            {images?.length > 0 && (
              <ImageGallery
                ref={imageGallery}
                items={images}
                showNav={false}
                autoPlay={false}
                showFullscreenButton={false}
                showPlayButton={false}
                thumbnailPosition="right"
                renderItem={renderItem}
                loading="lazy"
              />
            )}
            {settings.slideshowBannerUrl && (
              <div className="banner">
                <img
                  src={settings.slideshowBannerUrl}
                  alt="banner"
                  onLoad={() => {
                    slideshowContainer.current.style.setProperty('--slideshow-banner-height', `${document.querySelector('.banner')?.offsetHeight}px`);
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </Socket>
  );
};

export default Slideshow;
