import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, Spinner, Form } from 'react-bootstrap';
import classnames from 'classnames';
import useForm from 'react-hook-form';
import { RHFInput } from 'react-hook-form-input';
import { faExternalLinkAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ColorPicker from '../../../components/ColorPicker';
import EventSelector from '../../../components/EventSelector/Modal';
import EventSelectorButton from '../../../components/EventSelector/Button';
import UpdateButton from '../UpdateButton';

import { useUpdateEvent } from '../hooks';
import { updateSlideshow } from './apis';
import {
  defaultValues,
  transitionTimeOptions,
  transitionOptions,
  transitionDurationOptions,
} from './consts';

import style from './style.module.scss';

const Slideshow = ({ event = {} }) => {
  const isEventUpdating = useUpdateEvent();
  const {
    register,
    setValue,
    getValues,
    reset,
  } = useForm({ defaultValues });

  useEffect(() => {
    if (event.slideshow && !isEventUpdating) {
      reset(event.slideshow);
    }
  }, [event, isEventUpdating]);

  const updateEventHandler = async (eventIds) => {
    const data = { eventIds, slideshow: getValues() };
    return updateSlideshow(data);
  };

  return (
    <div className={classnames('d-flex', 'flex-column', 'flex-grow-1')}>
      <div className="pageHeader">
        <h4>Slideshow</h4>
        <EventSelectorButton showFutureEvents />
        <UpdateButton label="Update Event" updateHandler={updateEventHandler} />
        <EventSelector singleSelection showTemplate showFutureEvents />
      </div>

      {isEventUpdating && (
        <div className="flex-grow-1 d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {!isEventUpdating && (
        <div className={style.SlideshowPage}>
          <Card>
            <Card.Header className={style.cardHeader}>
              <h3>Settings</h3>
              <span>
                View Slideshow
                <div className={style.slideshowIcon}>
                  <a href="/slideshow" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </a>
                </div>
              </span>
            </Card.Header>
            <Card.Body>
              <Form>
                <Form.Group md="2" controlId="transitionTime">
                  <Form.Label>Play Interval</Form.Label>
                  <RHFInput
                    name="transitionTime"
                    as={(
                      <Form.Control as="select">
                        {transitionTimeOptions.map((option) => <option key={`option-${option.value}`} value={option.value}>{option.text}</option>)}
                      </Form.Control>
                    )}
                    register={register({ required: true })}
                    setValue={setValue}
                  />
                </Form.Group>
                <Form.Group md="2" controlId="transitionTime">
                  <Form.Label>Slide Duration</Form.Label>
                  <RHFInput
                    name="transitionDuration"
                    as={(
                      <Form.Control as="select">
                        {transitionDurationOptions.map((option) => <option key={`option-${option.value}`} value={option.value}>{option.text}</option>)}
                      </Form.Control>
                    )}
                    register={register({ required: true })}
                    setValue={setValue}
                  />
                </Form.Group>
                <Form.Group md="2" controlId="animation">
                  <Form.Label>Transition Type</Form.Label>
                  <RHFInput
                    name="animation"
                    as={(
                      <Form.Control as="select">
                        {transitionOptions.map((option) => <option key={`option-${option}`}>{option}</option>)}
                      </Form.Control>
                    )}
                    register={register({ required: true })}
                    setValue={setValue}
                  />
                </Form.Group>
                <Form.Group className={style.colorPicker} md="2" controlId="backgroundColor">
                  <Form.Label>Background Color</Form.Label>
                  <RHFInput
                    name="backgroundColor"
                    as={<ColorPicker />}
                    register={register({ required: true })}
                    setValue={setValue}
                  />
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </div>
      )}
    </div>
  );
};

export default connect(
  ({ events: { selectedEvents } }) => ({ event: selectedEvents[0] }),
)(Slideshow);
