import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Form } from 'react-bootstrap';

import { setFilterField } from './actions';
import { strToReactSelectItem } from './helpers';
import { dividerMargin } from './constants';

import Divider from '../../../components/Divider';
import style from './style.module.scss';

const SRSBlock = (props) => {
  const {
    availableSeatTypes,
    availableSections,
    availableRows,
    availableSeats,
    availableBoxes,
    availableSuites,
    customType1,
    customType2,
    customType3,
    sections,
    rows,
    seats,
    seatTypes,
    setFilterSeatTypesAction,
    setFilterSectionsAction,
    setFilterRowsAction,
    setFilterSeatsAction,
    setFilterBoxesAction,
    setFilterSuitesAction,
  } = props;
  const sectionsList = useMemo(() => {
    let sectionsToRender = availableSections; // stand
    if (!seatTypes.length || seatTypes.some(({ value }) => value === 'box')) {
      sectionsToRender = sectionsToRender.concat(availableBoxes);
    }
    if (!seatTypes.length || seatTypes.some(({ value }) => value === 'suite')) {
      sectionsToRender = sectionsToRender.concat(availableSuites);
    }
    if (!seatTypes.length || seatTypes.some(({ value }) => value === 'custom_type_1')) {
      sectionsToRender = sectionsToRender.concat(customType1);
    }
    if (!seatTypes.length || seatTypes.some(({ value }) => value === 'custom_type_2')) {
      sectionsToRender = sectionsToRender.concat(customType2);
    }
    if (!seatTypes.length || seatTypes.some(({ value }) => value === 'custom_type_3')) {
      sectionsToRender = sectionsToRender.concat(customType3);
    }

    return sectionsToRender.sort();
  }, [seatTypes]);

  useEffect(() => {
    setFilterSectionsAction([]);
    setFilterRowsAction([]);
    setFilterSeatsAction([]);
    setFilterBoxesAction([]);
    setFilterSuitesAction([]);
  }, [seatTypes]);

  return (
    <div className={style.customMultiValueInput}>
      <Form.Label className={style.FormLabel}>Seat Type</Form.Label>
      <Select
        isMulti
        value={seatTypes}
        options={availableSeatTypes.map(strToReactSelectItem)}
        classNamePrefix="react-select"
        onChange={setFilterSeatTypesAction}
      />

      <Divider className={style.divider} margin={dividerMargin} />

      <div className={style.customMultiValueInput}>
        <Form.Label className={style.FormLabel}>Section</Form.Label>
        <Select
          isMulti
          key="sections"
          value={sections}
          options={sectionsList.map(strToReactSelectItem)}
          classNamePrefix="react-select"
          onChange={setFilterSectionsAction}
        />

        <Divider className={style.divider} margin={dividerMargin} />

        <Form.Label className={style.FormLabel}>Row</Form.Label>
        <Select
          isMulti
          key="rows"
          value={rows}
          options={availableRows.map(strToReactSelectItem)}
          classNamePrefix="react-select"
          onChange={setFilterRowsAction}
        />

        <Divider className={style.divider} margin={dividerMargin} />

        <Form.Label className={style.FormLabel}>Seat</Form.Label>
        <Select
          isMulti
          key="seat"
          value={seats}
          options={availableSeats.map(strToReactSelectItem)}
          classNamePrefix="react-select"
          onChange={setFilterSeatsAction}
        />
      </div>
    </div>
  );
};

export default connect(
  ({
    album: {
      filterForm: {
        formOptions: {
          seatTypes: availableSeatTypes,
          sections: availableSections,
          rows: availableRows,
          seats: availableSeats,
          boxes: availableBoxes,
          suites: availableSuites,
          customType1,
          customType2,
          customType3,
        },
        tempData: {
          seatTypes,
          sections,
          rows,
          seats,
          boxes,
          suites,
        },
      },
    },
  }) => ({
    availableSeatTypes,
    availableSections,
    availableBoxes,
    availableSuites,
    availableRows,
    availableSeats,
    customType1,
    customType2,
    customType3,
    seatTypes,
    sections,
    rows,
    seats,
    boxes,
    suites,
  }),
  {
    setFilterSeatTypesAction: setFilterField('seatTypes'),
    setFilterSectionsAction: setFilterField('sections'),
    setFilterRowsAction: setFilterField('rows'),
    setFilterSeatsAction: setFilterField('seats'),
    setFilterBoxesAction: setFilterField('sections'),
    setFilterSuitesAction: setFilterField('sections'),
  },
)(SRSBlock);
