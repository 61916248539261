import React from 'react';
import { Button } from 'react-bootstrap';
import { ReactSwal } from '../../../../helpers/swal';

export default (endTime, updateStatusFn) => {
  const yesBtnHandler = (evt) => {
    evt.nativeEvent.stopImmediatePropagation();
    ReactSwal.close();
    // Yes: update event endtime to now and set status to post.
    updateStatusFn(true);
  };

  const noBtnHandler = (evt) => {
    evt.nativeEvent.stopImmediatePropagation();
    ReactSwal.close();
    // No: just set status to post without updating the endtime.
    updateStatusFn(false);
  };

  return ReactSwal.fire({
    title: 'Confirm Event Off',
    type: 'warning',
    html: (
      <div>
        <p>
          Event will be off at
          {' '}
          <strong>{endTime}</strong>
          , scheduler may turn it back on shortly.
          Would you like to move that to now?
        </p>
        <br />
        <Button
          variant="danger"
          className="swal2-confirm btn btn-danger btn-lg"
          onClick={yesBtnHandler}
        >
          Yes, update to now
        </Button>
        &nbsp;
        &nbsp;
        &nbsp;
        <Button
          className="swal2-confirm btn btn-danger btn-lg bg-white"
          variant="default"
          onClick={noBtnHandler}
          autoFocus
        >
          No, just set to Post
        </Button>
      </div>
    ),
  });
};
