export const transitionTimeOptions = [
  { text: '1 second', value: 1000 },
  { text: '2 seconds', value: 2000 },
  { text: '3 seconds', value: 3000 },
  { text: '4 seconds', value: 4000 },
  { text: '5 seconds', value: 5000 },
  { text: '6 seconds', value: 6000 },
  { text: '7 seconds', value: 7000 },
  { text: '8 seconds', value: 8000 },
  { text: '9 seconds', value: 9000 },
  { text: '10 seconds', value: 10000 },
];

export const transitionDurationOptions = [
  { text: '0.2 seconds', value: 200 },
  { text: '0.4 seconds', value: 400 },
  { text: '0.6 seconds', value: 600 },
  { text: '0.8 seconds', value: 800 },
  { text: '1 second', value: 1000 },
];

export const transitionOptions = [
  'ease',
  'linear',
  'ease-in',
  'ease-out',
  'ease-in-out',
];

export const defaultValues = {
  transitionTime: 5000,
  transitionDuration: 400,
  animation: 'ease-in-out',
  backgroundColor: '#000',
};
