import { SET_FILTER_FILED, RESET_FILTER_FORM, SET_ALBUM_FILTER } from './types';
import { SET_SELECTED_EVENTS } from '../../../store/types/events';
import { FETCH_SNAPSHOTS_FILTER_OPTIONS } from '../../../store/types/snapshots';

import { getStartOfEpoch, getEndOfEpoch } from '../../../helpers/utils';

const formData = {
  emotions: [],
  userIDs: [],
  cameras: [],
  boxes: [],
  suites: [],
  sections: [],
  rows: [],
  seats: [],
  overlays: [],
  seatTypes: [],
  timeSince: getStartOfEpoch().getTime(),
  timeUntil: getEndOfEpoch().getTime(),
};

const initialState = {
  filterBarDisplayed: false,
  isFetching: true,
  error: false,
  formOptions: {},
  data: { ...formData },
  tempData: { ...formData },
};

export default function (state = initialState, { type, payload } = {}) {
  switch (type) {
  case FETCH_SNAPSHOTS_FILTER_OPTIONS: {
    const { error, data } = payload;

    return {
      ...state,
      isFetching: !error && !data,
      error,
      formOptions: data || state.formOptions,
      tempData: {
        ...state.tempData,
      },
    };
  }

  case SET_ALBUM_FILTER: {
    return {
      ...state,
      data: { ...state.tempData },
    };
  }

  case SET_FILTER_FILED: {
    const { field, value } = payload;

    return {
      ...state,
      tempData: {
        ...state.tempData,
        // if field is undefined, use initial value
        [field]: value !== undefined ? value : initialState.tempData[field],
      },
    };
  }

  case SET_SELECTED_EVENTS: {
    return {
      ...state,
      data: {
        ...state.data,
        timeSince: null,
        timeUntil: null,
      },
      tempData: {
        ...state.tempData,
        timeSince: null,
        timeUntil: null,
      },
    };
  }

  case RESET_FILTER_FORM: {
    return {
      ...state,
      data: { ...initialState.data },
      tempData: {
        ...initialState.data,
      },
    };
  }

  default: {
    return state;
  }
  }
}
