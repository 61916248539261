import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { Event } from 'react-socket-io';
import classNames from 'classnames';

import PaginationContainer from '../PaginationContainer';
import OverlaidSnapshot from './OverlaidSnapshot';

import { setFavorite } from '../actions';
import { useDelayedRender } from '../../../helpers/hooks';
import { setBannedSnapshots, setBannedUser } from '../../../store/actions/snapshots';
import {
  addSnapshot,
  removeSnapshots,
  setShared,
  setLightBoxSnapshot,
} from './actions';

import style from './style.module.scss';

const AlbumPhotoContainer = (props) => {
  const {
    isFetching,
    error,
    snapshots,
    noEventSelected,
    autoUpdateEnabled,
    addSnapshotAction,
    removeSnapshotsAction,
    setFavoriteAction,
    setBannedUserAction,
    setBannedSnapshotsAction,
    setSharedSnapshotAction,
    setLightBoxSnapshotAction,
  } = props;

  // we need this variable to display loading indicator in case of the first render,
  // and skip snapshots render, before we set isFetching prop to true
  const canRender = useDelayedRender();

  const getBody = () => {
    if (!noEventSelected && (isFetching || !canRender)) {
      return (
        <div className={style.LoadingContainer}>
          <Spinner animation="border" variant="primary" />
        </div>
      );
    }

    // TODO: style these messages

    if (error) {
      return <p>Error fetching media.</p>;
    }

    if (noEventSelected) {
      return <p>Please select an event.</p>;
    }

    if (snapshots.length === 0) {
      return <p>No media available.</p>;
    }

    const selectionMode = snapshots.some((i) => i.selected);

    return (
      <div className={classNames(style.photos, 'row', { [style.selectionMode]: selectionMode })}>
        {snapshots.map((snapshot) => (
          <OverlaidSnapshot
            key={snapshot._id}
            snapshot={snapshot}
            onClick={setLightBoxSnapshotAction}
          />
        ))}
      </div>
    );
  };

  const onBannedEvent = useCallback((bannedData) => {
    if (Array.isArray(bannedData)) {
      setBannedSnapshotsAction(bannedData);
    } else {
      setBannedUserAction(bannedData);
    }
  });

  return (
    <div className={style.AlbumPhotoContainer}>
      {getBody()}
      <PaginationContainer />

      {autoUpdateEnabled && (
        <Event event="monitor newSnapshot" handler={addSnapshotAction} />
      )}

      <Event event="monitor snapshotDeleted" handler={removeSnapshotsAction} />
      <Event event="monitor favoritedSnapshots" handler={setFavoriteAction} />
      <Event event="monitor bannedChanged" handler={onBannedEvent} />
      <Event event="monitor snapshotShared" handler={setSharedSnapshotAction} />
    </div>
  );
};

export default connect(
  ({
    snapshots: {
      isFetching,
      error,
      items,
    },
    events: {
      selectedEvents,
    },
    album: {
      options: {
        autoUpdateEnabled,
      },
    },
  }) => ({
    isFetching,
    error,
    snapshots: items,
    autoUpdateEnabled,
    noEventSelected: selectedEvents.length === 0,
  }),
  {
    addSnapshotAction: addSnapshot,
    removeSnapshotsAction: removeSnapshots,
    setFavoriteAction: setFavorite,
    setBannedUserAction: setBannedUser,
    setBannedSnapshotsAction: setBannedSnapshots,
    setSharedSnapshotAction: setShared,
    setLightBoxSnapshotAction: setLightBoxSnapshot,
  },
)(AlbumPhotoContainer);
