import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import classnames from 'classnames';

import Section from './Section';
import ColorPicker from '../../../../components/ColorPicker';

import { useUpdateEvent } from '../../hooks';
import {
  setColors,
  setIconColor,
  setEmailColor,
  setThemeColor,
  setSlideshowColor,
} from '../actions';

import style from './style.module.scss';

const ColorsForm = ({
  colors,
  event,
  setColorsAction,
  setThemeColorAction,
  setIconColorAction,
  setEmailColorAction,
  setSlideshowColorAction,
}) => {
  const isEventUpdating = useUpdateEvent();

  useEffect(() => {
    if (event && !isEventUpdating) {
      const {
        theme,
        iconColor,
        emailSettings,
        slideshow,
      } = event;

      setColorsAction({
        theme,
        iconColor,
        emailSettings,
        slideshowColor: slideshow.backgroundColor,
      });
    }
  }, [event, isEventUpdating]);

  if (!colors) {
    return (
      <div className="flex-grow-1 d-flex align-items-center justify-content-center">
        <Spinner as="span" animation="border" aria-hidden="true" />
      </div>
    );
  }

  return (
    <div className={classnames('flex-grow-1', style.colorsForm)}>
      <div>
        <Section title="Background">
          <ColorPicker
            title="App Background Color"
            colorName="backgroundColor"
            value={colors.theme.backgroundColor}
            onChange={setThemeColorAction}
          />
          <ColorPicker
            title="Slideshow Background Color"
            colorName="slideshowColor"
            value={colors.slideshowColor}
            onChange={setSlideshowColorAction}
          />
        </Section>

        <Section title="Buttons">
          <ColorPicker
            title="Primary Color"
            colorName="primaryColor"
            value={colors.theme.primaryColor}
            onChange={setThemeColorAction}
          />
          <ColorPicker
            title="Secondary Color"
            colorName="secondaryColor"
            value={colors.theme.secondaryColor}
            onChange={setThemeColorAction}
          />
          <ColorPicker
            title="Active Color"
            colorName="activeColor"
            value={colors.theme.activeColor}
            onChange={setThemeColorAction}
          />
          <ColorPicker
            title="Download Button Color"
            colorName="downloadButtonColor"
            value={colors.theme.downloadButtonColor}
            onChange={setThemeColorAction}
          />
          <ColorPicker
            title="Email Share Button Color"
            colorName="emailButtonColor"
            value={colors.theme.emailButtonColor}
            onChange={setThemeColorAction}
          />
          <ColorPicker
            title="Web Share Button Color"
            colorName="webShareButtonColor"
            value={colors.theme.webShareButtonColor}
            onChange={setThemeColorAction}
          />
          <ColorPicker
            title="PTZ Control-Button Color"
            colorName="ptzControlButtonColor"
            value={colors.theme.ptzControlButtonColor}
            onChange={setThemeColorAction}
          />
          <ColorPicker
            title="PTZ Control-Icon Color"
            colorName="ptzControlIconColor"
            value={colors.theme.ptzControlIconColor}
            onChange={setThemeColorAction}
          />
          <ColorPicker
            title="PTZ Control-Camera Button Color"
            colorName="cameraButtonColor"
            value={colors.theme.cameraButtonColor}
            onChange={setThemeColorAction}
          />
          <ColorPicker
            title="PTZ Control-Camera Icon Color"
            colorName="cameraIconColor"
            value={colors.theme.cameraIconColor}
            onChange={setThemeColorAction}
          />
        </Section>

        <Section title="Text">
          <ColorPicker
            title="Main Text Color"
            colorName="mainTextColor"
            value={colors.theme.mainTextColor}
            onChange={setThemeColorAction}
          />
          <ColorPicker
            title="Primary Text Color"
            colorName="primaryTextColor"
            value={colors.theme.primaryTextColor}
            onChange={setThemeColorAction}
          />
          <ColorPicker
            title="Secondary Text Color"
            colorName="secondaryTextColor"
            value={colors.theme.secondaryTextColor}
            onChange={setThemeColorAction}
          />
          <ColorPicker
            title="Active Text Color"
            colorName="activeTextColor"
            value={colors.theme.activeTextColor}
            onChange={setThemeColorAction}
          />
        </Section>

        <Section title="Graphics">
          <ColorPicker
            title="Cloud Background"
            colorName="cloudBackground"
            value={colors.iconColor.cloudBackground}
            onChange={setIconColorAction}
          />
          <ColorPicker
            title="Map Grid"
            colorName="mapGrid"
            value={colors.iconColor.mapGrid}
            onChange={setIconColorAction}
          />
          <ColorPicker
            title="Camera Lens"
            colorName="cameraLens"
            value={colors.iconColor.cameraLens}
            onChange={setIconColorAction}
          />
          <ColorPicker
            title="Stadium Seats"
            colorName="stadiumSeats"
            value={colors.iconColor.stadiumSeats}
            onChange={setIconColorAction}
          />
          <ColorPicker
            title="Stadium Walls"
            colorName="stadiumWalls"
            value={colors.iconColor.stadiumWalls}
            onChange={setIconColorAction}
          />
          <ColorPicker
            title="Stadium Doors"
            colorName="stadiumDoors"
            value={colors.iconColor.stadiumDoors}
            onChange={setIconColorAction}
          />
          <ColorPicker
            title="Stadium Flags"
            colorName="stadiumFlags"
            value={colors.iconColor.stadiumFlags}
            onChange={setIconColorAction}
          />
          <ColorPicker
            title="Zone Circle"
            colorName="circleZone"
            value={colors.iconColor.circleZone}
            onChange={setIconColorAction}
          />
          <ColorPicker
            title="Zone Circle Person"
            colorName="circleZoneMan"
            value={colors.iconColor.circleZoneMan}
            onChange={setIconColorAction}
          />
          <ColorPicker
            title="Success Circle"
            colorName="successCircle"
            value={colors.iconColor.successCircle}
            onChange={setIconColorAction}
          />
          <ColorPicker
            title="Error Circle"
            colorName="errorCircle"
            value={colors.iconColor.errorCircle}
            onChange={setIconColorAction}
          />
          <ColorPicker
            title="Shimmer Main Color"
            colorName="shimmerBaseColor"
            value={colors.theme.shimmerBaseColor}
            onChange={setThemeColorAction}
          />
          <ColorPicker
            title="Shimmer Accent Color"
            colorName="shimmerAccentColor"
            value={colors.theme.shimmerAccentColor}
            onChange={setThemeColorAction}
          />
        </Section>

        <Section title="Email">
          <ColorPicker
            title="Email Background"
            colorName="bodyBgColor"
            value={colors.emailSettings.bodyBgColor}
            onChange={setEmailColorAction}
          />
          <ColorPicker
            title="Email Text"
            colorName="bodyTextColor"
            value={colors.emailSettings.bodyTextColor}
            onChange={setEmailColorAction}
          />
          <ColorPicker
            title="Email Button Color"
            colorName="buttonColor"
            value={colors.emailSettings.buttonColor}
            onChange={setEmailColorAction}
          />
          <ColorPicker
            title="Email Button Text Color"
            colorName="buttonTextColor"
            value={colors.emailSettings.buttonTextColor}
            onChange={setEmailColorAction}
          />
          <ColorPicker
            title="Email Header Background"
            colorName="headerBgColor"
            value={colors.emailSettings.headerBgColor}
            onChange={setEmailColorAction}
          />
          <ColorPicker
            title="Email Header Text"
            colorName="headerTextColor"
            value={colors.emailSettings.headerTextColor}
            onChange={setEmailColorAction}
          />
        </Section>
      </div>
    </div>
  );
};

export default connect(
  ({
    colors,
    events: { selectedEvents },
  }) => ({
    colors,
    event: selectedEvents[0],
  }),
  {
    setColorsAction: setColors,
    setThemeColorAction: setThemeColor,
    setIconColorAction: setIconColor,
    setEmailColorAction: setEmailColor,
    setSlideshowColorAction: setSlideshowColor,
  },
)(ColorsForm);
